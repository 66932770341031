import logo from './scarfilms.PNG';
import React, { useEffect, useState } from "react";
import './App.css';

function App() {
  const calculateTimeLeft = () => {
    const difference = +new Date(`2021-01-01`) - +new Date();
    let total = "";

    if (difference > 0) {
      let days = Math.floor(difference / (1000 * 60 * 60 * 24)) + " días";
      let hours = Math.floor((difference / (1000 * 60 * 60)) % 24) + " horas";
      let minutes = Math.floor((difference / 1000 / 60) % 60) + " minutos";
      let seconds = Math.floor((difference / 1000) % 60) + " segundos";
      total = days + " " + hours + " " + minutes + " " + seconds + " ";
    }
    return total;
  };

  const [total, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  <strong id="Contador">{total}</strong>

  return (
    <div className="App">
      <header className="App-header"><br></br><br></br><br></br><br></br><br></br>
        <img src={logo} className="App-logo" alt="logo" /><br></br><br></br><br></br><br></br><br></br>
        <h1>En construcción</h1>
        {total.length ? total : <span>Tiempo excedido</span>}
      </header>
    </div>
  );
}

export default App;
